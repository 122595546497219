import { Injectable, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {

  public showLoader: boolean = false;

  constructor(
    private _httpClient: HttpClient,
    public router: Router,
    public ngZone: NgZone,
    public toster: ToastrService) {
  }

  ngOnInit(): void { }

  //sign in function
  SignIn(email, password) {

    let url = environment.api + '/api/login';
    let body = { email: email, password: password };
    var options = { headers: { 'Accept': 'application/json' }, reportProgress: true };

    this._httpClient.post(url, body, options)
      .subscribe((response: any) => {
        // console.log(response)
        if (response) {
          localStorage.setItem('user', JSON.stringify({token: response}));
          this.router.navigate(['/animals']);
        }
      },
        err => {
          console.warn(err);
          this.toster.error('L\'usuari o la contrasenya introduïda es incorrecte.', 'Usuari o contrasenya incorrecte!', {
            progressBar: true,
          });
        });

    // if (email === 'ferran@novostorm.cat' && password === 'admin') {
    //   localStorage.setItem('user', JSON.stringify(user));
    //   this.router.navigate(['/animals']);
    // }

    // return this.afAuth.auth.signInWithEmailAndPassword(email, password)
    //   .then((result) => {
    //     if (result.user.emailVerified !== true) {
    //       this.SetUserData(result.user);
    //       this.SendVerificationMail();
    //       this.showLoader = true;
    //     } else {
    //       this.showLoader = false;
    //       this.ngZone.run(() => {
    //         this.router.navigate(['/auth/login']);
    //       });
    //     }
    //   }).catch((error) => {
    //   })
  }

  //Authentication for Login
  AuthLogin(provider) {
    // return this.afAuth.auth.signInWithPopup(provider)
    //   .then((result) => {
    //     this.ngZone.run(() => {
    //       this.router.navigate(['/dashboard/default']);
    //     });
    //     this.SetUserData(result.user);
    //   }).catch((error) => {
    //     window.alert(error);
    //   });
  }

  //Set user
  SetUserData(user) {
    // const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    // const userData: User = {
    //   email: user.email,
    //   displayName: user.displayName,
    //   uid: user.uid,
    //   photoURL: user.photoURL || 'assets/dashboeard/boy-2.png',
    //   emailVerified: user.emailVerified
    // };
    // userRef.delete().then(function () { })
    //   .catch(function (error) { });
    // return userRef.set(userData, {
    //   merge: true
    // });
  }

  // Sign out
  // SignOut() {
  //   this.router.routeReuseStrategy.shouldReuseRoute = function () {
  //     return false;
  //   };
  //   return this.afAuth.auth.signOut().then(() => {
  //     this.showLoader = false;
  //     localStorage.clear();
  //     this.cookieService.deleteAll('user', '/auth/login');
  //     this.router.navigate(['/auth/login']);
  //   });
  // }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user != null || user != undefined) ? true : false;
  }

}
