<!-- Page Header Start-->
<div class="page-main-header" [ngClass]="{'open' : navServices.collapseSidebar}" id="page-main-header">
  <div class="main-header-right row">
    <div class="main-header-left d-lg-none">
      <div class="logo-wrapper"><a href="javascript:void(0)"><img src="assets/images/endless-logo.png" alt=""></a></div>
    </div>
    <div class="mobile-sidebar">
      <div class="media-body text-right switch-sm">
        <label class="switch">
          <a href="javascript:void(0)" (click)="collapseSidebar()">
            <app-feather-icons id="sidebar-toggle" [icon]="'align-left'"></app-feather-icons>
          </a>
        </label>
      </div>
    </div>
    <div class="nav-right col">

      <div style="display: flex; flex-direction: row;">
        <div style="display: inherit; flex: 1; justify-content: flex-start; align-items: center;"></div>
        <div style="display: inherit; flex: 1; justify-content: center; align-items: center;">
          <h3>{{title}}</h3>
        </div>
        <div style="display: inherit; flex: 1; justify-content: flex-end; align-items: center;">
          <app-feather-icons [icon]="'maximize'" (click)="toggleFullScreen()" style="cursor: pointer;" placement="bottom" ngbTooltip="Pantalla Completa"></app-feather-icons>
          <app-feather-icons class="m-l-10" style="cursor: pointer;" [icon]="'log-out'" (click)="logout()" placement="bottom" ngbTooltip="Tancar sessió"></app-feather-icons>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Page Header Ends -->