<div class="dark-body-only" id="outer-container">
  <div class="page-wrapper" [ngClass]="customizer.data.settings.sidebar.type" id="canvas-bookmark">
    <app-header (rightSidebarEvent)="rightSidebar($event)"></app-header>
    <div class="page-body-wrapper" [ngClass]="customizer.data.settings.sidebar.body_type">
      <div class="page-sidebar" id="pages-sidebar" [class.open]="navServices.collapseSidebar"
        [ngClass]="customizer.data.settings.sidebar_backround"
        [attr.sidebar-layout]="this.customizer.data.settings.sidebar_setting">
        <app-sidebar></app-sidebar>
      </div>
      <div class="page-body">
        <main [@animateRoute]="getRouterOutletState(o)">
          <router-outlet #o="outlet"></router-outlet>
        </main>
      </div>
    </div>
  </div>
</div>