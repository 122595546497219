import { Routes } from '@angular/router';

export const content: Routes = [
  {
    path: 'animals',
    loadChildren: () => import('../../components/animals/animals.module').then(m => m.AnimalsModule),
    data: {
      breadcrumb: "Animals"
    }
  },
  {
    path: 'tractaments',
    loadChildren: () => import('../../components/tractaments/tractaments.module').then(m => m.TractamentsModule),
    data: {
      breadcrumb: "Tractaments"
    }
  },
  {
    path: 'persones',
    loadChildren: () => import('../../components/persones/persones.module').then(m => m.PersonesModule),
    data: {
      breadcrumb: "Persones"
    }
  },
  {
    path: 'usuaris',
    loadChildren: () => import('../../components/usuaris/usuaris.module').then(m => m.UsuarisModule),
    data: {
      breadcrumb: "Usuaris"
    }
  },
  {
    path: 'llistats',
    loadChildren: () => import('../../components/llistats/llistats.module').then(m => m.LlistatsModule),
    data: {
      breadcrumb: "Llistats"
    }
  },
  {
    path: 'moviments',
    loadChildren: () => import('../../components/moviments/moviments.module').then(m => m.MovimentsModule),
    data: {
      breadcrumb: "Persones"
    }
  },
  {
    path: 'manteniments',
    loadChildren: () => import('../../components/manteniments/manteniments.module').then(m => m.MantenimentsModule),
    data: {
      breadcrumb: "Manteniments"
    }
  }
];