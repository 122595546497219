import { Injectable, HostListener } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';

// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}
///api/DocsAnimals/download/{id}
@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false
	public fullScreen = false;

	public onTitleChanged: BehaviorSubject<string>;

	constructor() {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}

		this.onTitleChanged = new BehaviorSubject('');
	}

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}

	MENUITEMS: Menu[] = [
		{
			path: '/animals', title: 'Animals', icon: 'github', type: 'link'
		},
		{
			path: '/moviments', title: 'Moviments', icon: 'repeat', type: 'link'
		},
		{
			path: '/tractaments', title: 'Tractaments', icon: 'activity', type: 'link'
		},
		{
			path: '/persones', title: 'Persones', icon: 'users', type: 'link'
		},
		{
			path: '/usuaris', title: 'Usuaris', icon: 'user', type: 'link'
		},
		{
			title: 'Manteniments', icon: 'settings', type: 'sub', active: false, children: [
				{ path: '/manteniments/tipus-tractament', title: 'Tipus Tractament', type: 'link' },
				{ path: '/manteniments/estats-animal', title: 'Estats Animal', type: 'link' },
				{ path: '/manteniments/tipus-entrades-sortides', title: 'Tipus E-S', type: 'link' },
				{ path: '/manteniments/races', title: 'Races', type: 'link' },
				{ path: '/manteniments/motius', title: 'Motius', type: 'link' }
			]
		},
		{
			path: '/llistats', title: 'Llistats', icon: 'list', type: 'link'
		},
	]

	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
