<div class="page-wrapper">
	<div class="auth-bg">
		<div class="authentication-box">
      <div class="text-center"><img src="assets/images/logo_consell_garrotxa.png" alt="" width="40%"></div>
			<div class="text-center mt-2"><img src="assets/images/sigma.png" alt=""  width="40%"></div>
			<div class="card mt-4">
				<div class="card-body">
					<div class="text-center">
						<h4>INICIAR SESSIÓ</h4>
						<h6>Entra el teu usuari i contrasenya</h6>
					</div>
					<form class="theme-form" [formGroup]="loginForm">
						<div class="form-group">
							<label class="col-form-label pt-0">Correu electrònic</label>
							<input class="form-control" formControlName="email" type="email" required="">
							<div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required"
								class="text text-danger mt-1">
								El correu és obligatori
							</div>
							<div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email"
								class="text text-danger mt-1">
								El correu és incorrecta
							</div>
						</div>
						<div class="form-group">
							<label class="col-form-label">Contrasenya</label>
							<input class="form-control" type="password" formControlName="password" required="">
							<div *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
								class="text text-danger mt-1">
								La contrasenya és obligatoria
							</div>
						</div>
						<div class="form-group form-row mt-3 mb-0">
							<button class="btn btn-primary btn-block btn-lg"
								[class.loader--text]="authService.showLoader"
								[disabled]="!loginForm.valid" (click)="login()"
								type="submit"><span>Iniciar sessió</span>
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
